import request from "@/utils/request";
import qs from 'qs'

export const getProductList = data => {
  return request({
    url: "/WebProduct/GetProductList",
    method: "post",
    data: qs.stringify(data),
    type: "1"
  });
};

export const getProductTypeOne = () => {
  return request({
    url: "/WebProduct/GetProductTypeOne",
    method: "get",
    type: "1"
  });
};

export const getProductDetails = (data) => {
  console.log(data)
  return request({
    url: "/WebProduct/GetProductDetails",
    method: "get",
    params: data,
    type: "1"
  });
};

export const getProductTypeTwo = data => {
  return request({
    url: "/WebProduct/GetProductTypeTwo",
    method: "get",
    params: data,
    type: "1"
  });
};

export const addShoppingCart = data => {
  return request({
    url: "/WebProduct/AddShoppingCart",
    method: "post",
    data: qs.stringify(data),
    type: "1"
  });
};