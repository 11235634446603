import { checkPhone, GetPhoneCode, RegisterEnterprise,GetAreaList } from "@/api/enterpriseRegister";
const state = {
    check: false,
    isPhoneCode: false,
    registerSuccess: {},
    get_AreaList:[]
};

const mutations = {
    // SET_HOMEDATA: (state, homeData) => {
    //     console.log(homeData);
    //     state.homeData = homeData;
    //     state.hotNews = homeData.hotNews;
    // },
    // SET_HOTNEWS: (state, { data, count, lastCount }) => {
    //     data[lastCount].selected = false;
    //     data[count].selected = true;
    //     state.hotNews = [...data];
    // }
};

const actions = {
    async checkPhone({ commit }, payload) {
        const res = await checkPhone(payload);
        state.check = res.result
        return
    },
    async GetPhoneCode({ commit }, payload) {
        const res = await GetPhoneCode(payload);
        state.isPhoneCode = res.result.success
        return
    },
    async RegisterEnterprise({ commit }, payload) {
        const res = await RegisterEnterprise(payload);
        state.registerSuccess = res.result
        return
    },
    async GetAreaList({ commit }, payload) {
        const res = await GetAreaList(payload);
        state.get_AreaList = res.result
        return
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
