import request from "@/utils/request";
import qs from 'qs'

export const getShoppingCartList = () => {
  return request({
    url: "/WebProduct/GetShoppingCartList",
    method: "get",
    type: "1"
  });
};

export const deleteShoppingCart = (data) => {
  console.log(data)
  return request({
    url: "/WebProduct/DeleteShoppingCart",
    method: "post",
    data: qs.stringify(data),
    type: "1"
  });
};
export const updateShoppingCartCount = (data) => {
  console.log(data)
  return request({
    url: "/WebProduct/UpdateShoppingCartCount",
    method: "post",
    data: qs.stringify(data),
    type: "1"
  });
};
