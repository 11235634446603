import request from "@/utils/request";
import qs from 'qs'

export const GetOrderDetail = (data) => {
    return request({
        url: "/Order/GetOrderDetail",
        method: "get",
        params: data,
        type: "1"
    });
};
export const UpdateOrderPayMethod = (data) => {
    return request({
        url: "/Order/UpdateOrderPayMethod",
        method: "post",
        data: qs.stringify(data),
        type: "1"
    });
};
export const OrderPay = (data) => {
    return request({
        url: "/Order/OrderPay",
        method: "get",
        params: data,
        type: "1"
    });
};
export const UpdateOrderProductOrderStatus = (data) => {
    return request({
        url: "/Order/UpdateOrderProductOrderStatus",
        method: "post",
        data: qs.stringify(data),
        type: "1"
    });
};
export const OrderWxPay = (data) => {
    return request({
        url: "/Order/OrderWxPay",
        method: "get",
        params: data,
        type: "1"
    });
};
export const OrderCancel = (data) => {
    return request({
        url: "/Order/OrderCancel",
        method: "post",
        data: qs.stringify(data),
        type: "1"
    });
};
export const WxPayStatus = (data) => {
    return request({
        url: "/Order/WxPayStatus",
        method: "get",
        params: data,
        type: "1"
    });
};