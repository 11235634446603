<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { getToken } from "@/utils/cookies";
export default {
  name: "app",
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      getToken() &&
       	this.$store.dispatch("getShoppingCartCount");
    },
  },
};
</script>
<style lang="less">

/deep/ *{
  margin: 0;
  padding: 0;
  list-style: none;
}
html, body, #app{
	width: 100%;
	height: 100%;
	overflow: hidden;
}
</style>
