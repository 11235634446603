import { MoreNews, NewsDetail, SearchNews } from "@/api/commonProblem";
const state = {
    get_MoreNews: {},
    get_NewsDetail: {}
};

const mutations = {
    //   SET_HOMEDATA: (state, homeData) => {
    //     console.log(homeData);
    //     state.homeData = homeData;
    //     state.hotNews = homeData.hotNews;
    //   },
    //   SET_HOTNEWS: (state, { data, count, lastCount }) => {
    //     data[lastCount].selected = false;
    //     data[count].selected = true;
    //     state.hotNews = [...data];
    //   }
};

const actions = {
    async MoreNews({ commit }, payload) {
        const res = await MoreNews(payload);
        state.get_MoreNews = res.result
        return
    },
    async SearchNews({ commit }, payload) {
        const res = await SearchNews(payload);
        state.get_MoreNews = res.result
        return
    },
    async NewsDetail({ commit }, payload) {
        const res = await NewsDetail(payload);
        state.get_NewsDetail = res.result
        return
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
