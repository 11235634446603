import request from "@/utils/request";
import qs from 'qs'

export const GetShoppingAddress = (data) => {
    return request({
        url: "/WebPersonalCenter/GetShoppingAddress",
        method: "get",
        params: data,
        type: "1"
    });
};
export const DeleteShoppingAddress = (data) => {
    return request({
        url: "/WebPersonalCenter/DeleteShoppingAddress",
        method: "get",
        params: data,
        type: "1"
    });
};
export const ShoppingAddressList = (data) => {
    return request({
        url: "/WebPersonalCenter/ShoppingAddressList",
        method: "post",
        data: qs.stringify(data),
        type: "1"
    });
};
export const GetProductList = (data) => {
    return request({
        url: "/Order/GetProductList",
        method: "post",
        data: qs.stringify(data),
        type: "1"
    });
};
export const CreateOrder = (data) => {
    return request({
        url: "/Order/CreateOrder",
        method: "post",
        data: qs.stringify(data),
        type: "1"
    });
};
export const CreateShoppingAddress = (data) => {
    return request({
        url: "/WebPersonalCenter/CreateShoppingAddress",
        method: "post",
        data: qs.stringify(data),
        type: "1"
    });
};
export const UpdateShoppingAddress = (data) => {
    return request({
        url: "/WebPersonalCenter/UpdateShoppingAddress",
        method: "post",
        data: qs.stringify(data),
        type: "1"
    });
};