import { querySearchList, getListDetail, getListPage, getFetchGywm } from "@/api/sameList";
const state = {
	homeList: [],
	list: [],
	videoList: [],
	GywmQuery: {},
	pagination: {},
	homePagination: {},
	viodePagination: {},
	sameDetailDownData: {},
	sameDetailUpData: {},
	sameDetailNewData: {}
};

const mutations = {
	SET_LIST: (state, result) => {
		let { newsdetailVms, ...otherRes } = result
    state.list = newsdetailVms;
    state.pagination = otherRes;
  },
	SET_HOME_LIST: (state, result) => {
		let { newsdetailVms, ...otherRes } = result
    state.homeList = newsdetailVms;
    state.homePagination = otherRes;
  },
	SET_VIDEO_LIST: (state, result) => {
		let { list, ...otherRes } = result
    state.videoList = list;
    state.viodePagination = otherRes;
  },
	SET_GUWM: (state, result) => {
		let { query } = result
    state.GywmQuery = query || {};
  },
  SET_SAME_DETAIL: (state, data) => {
    state.sameDetailDownData = data.down;
    state.sameDetailUpData = data.up;
    state.sameDetailNewData = data.news;
  }
};

const actions = {
	async queryHomeSearchList ({ commit }, payload) {
		commit("UPDATE_LOADING", true, { root: true });
    const res = await querySearchList(payload);
    if (res.success) {
			commit("SET_HOME_LIST", res.result);
			commit("UPDATE_LOADING", false, { root: true });
      return res.success;
    } else {
      throw res;
    }
	},
	async querySearchList ({ commit }, payload) {
		commit("UPDATE_LOADING", true, { root: true });
    const res = await querySearchList(payload);
    if (res.success) {
			commit("SET_LIST", res.result);
			commit("UPDATE_LOADING", false, { root: true });
      return res.success;
    } else {
      throw res;
    }
	},
	async getListPage ({ commit }, payload) {
		commit("UPDATE_LOADING", true, { root: true });
    const res = await getListPage(payload);
    if (res.success) {
			commit("SET_VIDEO_LIST", res.result);
			commit("UPDATE_LOADING", false, { root: true });
      return res.success;
    } else {
      throw res;
    }
	},
	async getListDetail({ commit }, payload) {
    const res = await getListDetail(payload);
    if (res.success) {
      commit("SET_SAME_DETAIL", res.result);
    } else {
      throw res;
    }
  },
	async fetchGywm({ commit }, payload) {
    const res = await getFetchGywm(payload);
    if (res.success) {
      commit("SET_GUWM", res.result);
    } else {
      throw res;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
