import request from '@/utils/request';
import { ajax } from '../utils/ajax';
import qs from 'qs'

// 获取报废列表
export const getScrapList = (data) => {
  return request({
    url: 'WebPersonalCenter/RequreCarWebList',
    method: 'post',
    data: qs.stringify(data),
    type: '1',
  });
};

//修改密码
export function updatePassword(data) {
  return request({
    url: `User/UpdatePassWord?CurrentPassword=${data.CurrentPassword}&&NewPassword=${data.NewPassword}`,
    method: 'post',
    data,
    type: '1',
  });
}

//个人中心-零配件订单
export function orderWebList(data) {
  console.log(data)
  let url = ''
  if (data.ProductOrderStatus && data.ProductOrderStatus !== "null") {
    url = `WebPersonalCenter/OrderWebList?ProductOrderStatus=${data.ProductOrderStatus}`
  } else {
    url = 'WebPersonalCenter/OrderWebList'
  }
  return request({
    url: url,
    method: 'post',
    data,
    type: '1',
  });
}

//大宗商品申请查看
export function commodityWebList(data) {
  return request({
    url: 'WebPersonalCenter/CommodityWebList',
    method: 'post',
    data,
    type: '1',
  });
}

// 创建用户来访记录
export function createUserVisitLog(data) {
  return request({
    url: 'TokenAuth/CreateUserVisitLog',
    method: 'post',
    data,
    type: '1',
  });
}

// 获取地区下拉框
export function getAreaList(data) {
  return request({
    url: 'Area/GetAreaList',
    method: 'get',
    data,
    type: '1',
  });
}

// 一键报废1
export function scrapOne(params) {
  return request({
    url: 'RequreCar/ScrapOne',
    method: 'post',
    params,
    type: '1',
  });
}

// 一键报废2
export function scrapTwo(params) {
  return ajax.post('RequreCar/scrapTwo', params);
}

// 一键报废3
export function scrapThree(params) {
  return request({
    url: 'RequreCar/ScrapThree',
    method: 'post',
    params,
    type: '1',
  });
}

// 一键报废3保存信息
export function scrapThreeSave(params) {
  ajax.post('RequreCar/ScrapThreeSave', params, function (res) {
    return res;
  });
  // return request({
  //   url: 'RequreCar/ScrapThreeSave',
  //   method: 'post',
  //   params,
  //   type: '1',
  // });
}
// 获取车辆类型大类
export function getParentCarType() {
  return request({
    url: 'CarType/ParentCarType',
    method: 'get',
    type: '1',
  });
}

// 获取车辆类型小类
export function getCarType(params) {
  return request({
    url: 'CarType/CarType',
    method: 'get',
    params,
    type: '1',
  });
}
// 上传报废订单照片
export function uploadScrapImage(data) {
  return request({
    url: 'File/UploadImg',
    method: 'post',
    params: { fileType: 2 },
    data,
    type: '1',
  });
}

//个人收货地址列表
export function shoppingAddressList(data) {
  return request({
    url: 'WebPersonalCenter/ShoppingAddressList',
    method: 'post',
    data,
    type: '1'
  })
}

//新增收货地址
export function createShoppingAddress(data) {
  return request({
    url: 'WebPersonalCenter/CreateShoppingAddress',
    method: 'post',
    data,
    type: '1'
  })
}

//删除收货地址
export function deleteShoppingAddress(data) {
  return request({
    url: `WebPersonalCenter/DeleteShoppingAddress?id=${data}`,
    method: 'get',
    type: '1'
  })
}

//设为默认地址
export function defaultAddress(data) {
  return request({
    url: `WebPersonalCenter/DefaultAddress?id=${data}`,
    method: 'get',
    type: '1'
  })
}

//单查收货地址
export function getShoppingAddressDetails(data) {
  return request({
    url: `WebPersonalCenter/GetShoppingAddress?id=${data}`,
    method: 'get',
    type: '1'
  })
}

// 流程进度
export function getOrderStatus(data) {
  return request({
    url: '/RequreCarOrderStatus/GetOrderStatus',
    method: 'get',
    params: data,
    type: '1'
  })
}

export function getRequreCar(data) {
  return request({
    url: '/RequreCar/Get',
    method: 'get',
    params: data,
    type: '1'
  })
}

//修改收货地址
export function updateShoppingAddress(data) {
  return request({
    url: 'WebPersonalCenter/UpdateShoppingAddress',
    method: 'post',
    data,
    type: '1'
  })
}