import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

const routes = [
  {
    path: "/",
    redirect: "/home",
    name: "Layout",
    component: () => import("@/components/Layout/index"),
    children: [
      {
        path: "/home",
        name: "home",
				meta: { scrollTop: 0, keepAlive: true },
        component: () => import("@/views/home/Home")
      },
      {
        path: "/fillInTheOrder/fillInTheOrder",
        name: "fillInTheOrder",
        component: () => import("@/views/fillInTheOrder/fillInTheOrder")
      },
      {
        path: "/home/oldCarCalculation",
        name: "oldCarCalculation",
        component: () => import("@/views/home/oldCarCalculation")
      },
      {
        path: "/home/governmentSubsidies",
        name: "governmentSubsidies",
        component: () => import("@/views/home/governmentSubsidies")
      },
      {
        path: "/home/residualValueQuery",
        name: "residualValueQuery",
        component: () => import("@/views/home/residualValueQuery")
      },
      {
        path: "/bulkCommodity/bulkCommodity",
        name: "bulkCommodity",
        component: () => import("@/views/bulkCommodity/bulkCommodity")
      },
      {
        path: "/bulkCommodity/details",
        name: "bulkCommodityDetails",
        component: () => import("@/views/bulkCommodity/details")
      },
      {
        path: "/bulkCommodity/particBidding",
        name: "particBiddingDetails",
        component: () => import("@/views/bulkCommodity/particBidding")
      },
      {
        path: "/register/login",
        name: "login",
        component: () => import("@/views/register/login")
      },
      {
        path: "/register/forgetPassword",
        name: "forgetPassword",
        component: () => import("@/views/register/forgetPassword")
      },
      {
        path: "/adressMaintain/adressMaintain",
        name: "adressMaintain",
        component: () => import("@/views/adressMaintain/adressMaintain")
      },
      {
        path: "/sparePartsMall/sparePartsMall",
        name: "sparePartsMall",
        component: () => import("@/views/sparePartsMall/sparePartsMall")
      },
      {
        path: "/sparePartsMall/productDetails",
        name: "productDetails",
        component: () => import("@/views/sparePartsMall/productDetails")
      },
      {
        path: "/register/register",
        name: "register",
        component: () => import("@/views/register/register")
      },
      {
        path: "/scrap/firstScrap",
        name: "firstScrap",
        component: () => import("@/views/scrap/firstScrap")
      },
      {
        path: "/scrap/scrapDetail",
        name: "scrapDetail",
        component: () => import("@/views/scrap/scrapDetail")
      },
      {
        path: "/scrap/secondScrap/:id",
        name: "secondScrap",
        component: () => import("@/views/scrap/secondScrap")
      },
      {
        path: "/scrap/thirdScrap",
        name: "thirdScrap",
        component: () => import("@/views/scrap/thirdScrap")
      },
      {
        path: "/scrap/fourthScrap",
        name: "fourthScrap",
        component: () => import("@/views/scrap/fourthScrap")
      },
      {
        path: "/aboutUs/aboutUs",
        name: "aboutUs",
        component: () => import("@/views/aboutUs/aboutUs")
      },
      {
        path: "/recycling-waste/list",
        name: "aboutUs",
        component: () => import("@/views/recycling-waste")
      },
      {
        path: "/new-car-trading/image-list",
        name: "aboutUs",
        component: () => import("@/views/new-car-trading")
      },
      {
        path: "/used-car-trading/image-list",
        name: "aboutUs",
        component: () => import("@/views/used-car-trading")
      },
      {
        path: "/shoppingCar/shoppingCar",
        name: "shoppingCar",
        component: () => import("@/views/shoppingCar/shoppingCar")
      },
      {
        path: "/personalCenter/personalCenter",
        name: "personalCenter",
        component: () => import("@/views/personalCenter/personalCenter")
      },
      {
        path: "/personalCenter/bulkView",
        name: "bulkView",
        component: () => import("@/views/personalCenter/bulkView")
      },
      {
        path: "/personalCenter/scrapDetails",
        name: "scrapDetails",
        component: () => import("@/views/personalCenter/scrapDetails")
      },
      {
        path: "/personalCenter/orderList/payment",
        name: "payment",
        component: () => import("@/views/personalCenter/orderList/payment")
      },
      {
        path: "/personalCenter/orderList/cancel",
        name: "cancel",
        component: () => import("@/views/personalCenter/orderList/cancel")
      },
      {
        path: "/personalCenter/orderList/details",
        name: "orderListDetails",
        component: () => import("@/views/personalCenter/orderList/details")
      },
      {
        path: "/successfulTrade/successfulTrade",
        name: "successfulTrade",
        component: () => import("@/views/successfulTrade/successfulTrade")
      },
      {
        path: "/pay/pay",
        name: "pay",
        component: () => import("@/views/pay/pay")
      },
      {
        path: "/scrapGuide/scrapGuide",
        name: "scrapGuide",
        component: () => import("@/views/scrapGuide/scrapGuide")
      },
      {
        path: "/enterpriseRegister/enterpriseRegister",
        name: "enterpriseRegister",
        component: () => import("@/views/enterpriseRegister/enterpriseRegister")
      },
      {
        path: "/scrapData/scrapData",
        name: "scrapData",
        component: () => import("@/views/scrapData/scrapData")
      },
      {
        path: "/scrapWarn/scrapWarn",
        name: "scrapWarn",
        component: () => import("@/views/scrapWarn/scrapWarn")
      },
      {
        path: "/news/news",
        name: "news",
        component: () => import("@/views/news/news")
      },
      {
        path: "/news/search-news",
        name: "searchNews",
        component: () => import("@/views/news/search-news")
      },
      {
        path: "/news/newsData",
        name: "newsData",
        component: () => import("@/views/news/newsData")
      },
      {
				path: "/carsite/same-list/(corporateNews|grassrootsDynamic|companyCulture|videoCenter|aboutUs|groupNews|staffStyle|searchKeywords|noticeInfo)/list",
				name: "sameList",
				meta: { scrollTop: 0, keepAlive: true },
				component: () => import("@/views/same-list")
			},
			{
				path: "/carsite/same-list/(corporateNews|grassrootsDynamic|companyCulture|videoCenter|aboutUs|groupNews|staffStyle|searchKeywords|noticeInfo)/detail",
				name: "sameListDetail",
				meta: { scrollTop: 0, keepAlive: true },
				component: () => import("@/views/same-list/detail")
			},
    ]
  },
  {
    path: "/pay/apilyPay",
    name: "apilyPay",
    component: () => import("@/views/pay/apilyPay")
  },
  {
    path: "/pay/wxPay",
    name: "wxPay",
    component: () => import("@/views/pay/wxPay")
  }
];

const router = new VueRouter({
	routes,
	mode: 'history', // 去掉url中的#
	scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});
export default router;
