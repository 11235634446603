
import { getOrderDetail } from "@/api/orderListDetail";
const state = {
  orderDetailData: {}
};

const mutations = {
  SET_ORDERDETAILDATA: (state, result) => {
    state.orderDetailData = result;
  },
};

const actions = {
  async getOrderDetail({ commit }, payload) {
    const res = await getOrderDetail(payload);
    if (res.success) {
      commit("SET_ORDERDETAILDATA", res.result);
      return res.result
    } else {
      throw res;
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};

