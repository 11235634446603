import { Check, GetPhoneCode, RegisterOwner } from "@/api/register";
const state = {
    get_Check: {},
    get_GetPhoneCode: {},
    get_RegisterOwner: {},
};

const mutations = {
    //   SET_HOMEDATA: (state, homeData) => {
    //     console.log(homeData);
    //     state.homeData = homeData;
    //     state.hotNews = homeData.hotNews;
    //   },
    //   SET_HOTNEWS: (state, { data, count, lastCount }) => {
    //     data[lastCount].selected = false;
    //     data[count].selected = true;
    //     state.hotNews = [...data];
    //   }
};

const actions = {
    async Check({ commit }, payload) {
        const res = await Check(payload);
        state.get_Check = res.result
        return
    },
    async GetPhoneCode({ commit }, payload) {
        const res = await GetPhoneCode(payload);
        state.get_GetPhoneCode = res.result
        return
    },
    async RegisterOwner({ commit }, payload) {
        const res = await RegisterOwner(payload);
        state.get_RegisterOwner = res.result
        return
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
