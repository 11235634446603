import { userLogin } from '@/api/user';
// import { setToken, removeToken, setUserInfo, removeUserInfo } from '@/utils/cookies';
const state = {
  token: ''
};

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_USER_INFO: (state, userInfo) => {
    state.userInfo = userInfo;
  }
};

const actions = {
  // user login
  async login({ commit }, userInfo) {
    const res = await userLogin(userInfo);
    const { isLogin } = res.result;
    if (isLogin) {
      const token = res.result.accessToken;
      commit('SET_TOKEN', token);
      setToken(token);
      //这里存储用户的头像用户名 未完成
      const { userName, avatar } = res.result;
      setUserInfo({ userName, avatar });
    } else this.$message.error(res.result.msg);
    return isLogin;
  },

  // user logout
  logout({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      commit('SET_TOKEN', '');
      commit('SET_USER_INFO', {});
      removeToken();
      removeUserInfo();
      location.reload();
      resolve();
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
