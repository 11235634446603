
import { downlodaFile } from "@/api/baseAPI";
const state = {
  orderDetailData: {}
};

const mutations = {
};

const actions = {
  async downlodaFile({ commit }, payload) {
    const res = await downlodaFile(payload);
    return res
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};

