import { queryHomeData, queryDashboardData, createSuggestions, ResidualSearch, CalculateCarValue, getFetchYwhzrx } from "@/api/home";
import Lodash from 'lodash'
const state = {
  homeData: {},
  hotNews: [],
  YwhzrxData: [],
  get_ResidualSearch: {},
  get_CalculateCarValue: {},
  dashboardData: {},
	selectVideoConfig: {},
	videoList: [],
};

const mutations = {
  SET_HOMEDATA: (state, homeData) => {
    // console.log(homeData);
    state.homeData = homeData;
    state.hotNews = homeData.hotNews;
  },
  SET_YWHZRX: (state, YwhzrxData) => {
    // console.log(homeData);
    state.YwhzrxData = YwhzrxData;
  },
  SET_HOTNEWS: (state, { data, count, lastCount }) => {
    data[lastCount].selected = false;
    data[count].selected = true;
    state.hotNews = [...data];
  },
  updateState: (state, payload) => {
		Object.entries(payload).forEach((item) => {
			state[item[0]] = item[1]
		})
	},
	SET_DATA: (state, dashboardData) => {
		if(Array.isArray(dashboardData.video) && dashboardData.video[0]){
			state.selectVideoConfig = dashboardData.video[0]
		}
		if(Array.isArray(dashboardData.video)){
			state.videoList = Lodash.cloneDeep(dashboardData.video).splice(0, 3)
		}
    state.dashboardData = dashboardData;
	},
};

const actions = {
	async queryHomeData ({ commit }) {
		commit("UPDATE_LOADING", true, { root: true });
    const res = await queryHomeData();
    if (res.success) {
			commit("SET_HOMEDATA", res.result);
			commit("UPDATE_LOADING", false, { root: true });
      return res.success;
    } else {
      throw res;
    }
  },
  async queryDashboardData ({ commit }) {
		commit("UPDATE_LOADING", true, { root: true });
    const res = await queryDashboardData();
    if (res.success) {
			commit("SET_DATA", res.result);
			commit("UPDATE_LOADING", false, { root: true });
      return res.success;
    } else {
      throw res;
    }
  },
  async fetchYwhzrx ({ commit }) {
    const res = await getFetchYwhzrx();
    if (res.success) {
			commit("SET_YWHZRX", res.result);
      return res.success;
    } else {
      throw res;
    }
  },
  async createSuggestions({ commit }, payload) {
    const res = await createSuggestions(payload);
    if (res.success) {
      return res.success;
    } else {
      throw res;
    }
  },
  async ResidualSearch({ commit }) {
    const res = await ResidualSearch();
    state.get_ResidualSearch = res.result
    return
  },
  async CalculateCarValue({ commit }, payload) {
    const res = await CalculateCarValue(payload);
    state.get_CalculateCarValue = res.result
    return
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
