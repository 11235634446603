import Cookies from 'js-cookie';

const TokenKey = 'user-token';

// export function getToken() {
//   return Cookies.get(TokenKey);
// }
export function getToken() {
 return Cookies.get("token")
}

// export function setToken(token) {
//   return Cookies.set(TokenKey, token);
// }

export function removeToken() {
  return sessionStorage.removeItem('token');
}
// const userInfoKey = 'user-info';
// export const setUserInfo = (data) => {
//   return Cookies.set(userInfoKey, data);
// };
export function getUserInfo() {
  // return window.sessionStorage.getItem('name');
  return JSON.parse(Cookies.get('userInfo') || '{}')
};

export function removeUserInfo(){
  return window.sessionStorage.removeItem('userInfo');
};
