import request from '@/utils/request';
import qs from 'qs'
const ajax = {
    get(url, parameter) {
        return request({
            url: url,
            method: 'get',
            type: '1',
            params: parameter
        })
    },
    post(url, parameter, successFunc) {
        request({
            url: url,
            method: 'post',
            type: '1',
            data: qs.stringify(parameter)
        }).then(res => {
            if (successFunc) {
                return successFunc(res)
            }
        }).catch(err => {
            if (err) {
                console.log(err)
            }
        }).finally(() => {
        })
    }
}
export {
    ajax
}