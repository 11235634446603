import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/css/font.css";
import "./assets/css/style.css";
import "./assets/css/popover.less";
import "./assets/js/flexible";
import "./assets/css/quill.snow.css";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "./permission";
import axios from "axios";
import VideoPlayer from "vue-video-player";
// 引入 vue-awesome-swiper 组件
import VueAwesomeSwiper from "vue-awesome-swiper";

import "swiper/css/swiper.css";
require("video.js/dist/video-js.css");
require("vue-video-player/src/custom-theme.css");

Vue.prototype.$axios = axios;
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(VideoPlayer);
// 导入 vue 中
Vue.use(VueAwesomeSwiper);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
