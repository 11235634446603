import { getScrapList } from "@/api/personalCenter";
const state = {
    get_ScrapList:[]
};

const mutations = {
    // SET_HOMEDATA: (state, homeData) => {
    //     console.log(homeData);
    //     state.homeData = homeData;
    //     state.hotNews = homeData.hotNews;
    // },
    // SET_HOTNEWS: (state, { data, count, lastCount }) => {
    //     data[lastCount].selected = false;
    //     data[count].selected = true;
    //     state.hotNews = [...data];
    // }
};

const actions = {
    async getScrapList({ commit }, payload) {
        const res = await getScrapList(payload);
        state.get_ScrapList = res.result
        return
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
