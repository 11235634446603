import request from "@/utils/request";
import qs from 'qs'

export const querySearchList = (data) => {
  return request({
    url: "/WebSite/Search",
    method: "post",
    data: qs.stringify(data),
    type: "1"
  });
};

export const getListPage = (data) => {
  return request({
    url: "/WebSite/GetListPage",
    method: "post",
    data: qs.stringify(data),
    type: "1"
  });
};

export const getListDetail = data => {
  return request({
    url: "/WebSite/NewsDetail",
    method: "get",
    params: data,
    type: "1"
  });
};

export const getFetchGywm = data => {
  return request({
    url: "/WebSite/GetGywm",
    method: "get",
    params: data,
    type: "1"
  });
};