import { ShoppingAddressList, GetProductList, CreateOrder, GetShoppingAddress, DeleteShoppingAddress, CreateShoppingAddress, UpdateShoppingAddress } from "@/api/fillInTheOrder";
const state = {
    addressList: [],
    get_Product: [],
    get_CreateOrder: {},
    get_GetShoppingAddress: {},
    get_CreateShoppingAddress: {},
    get_UpdateShoppingAddress: {}
};

const mutations = {
    // SET_HOMEDATA: (state, homeData) => {
    //     console.log(homeData);
    //     state.homeData = homeData;
    //     state.hotNews = homeData.hotNews;
    // },
    // SET_HOTNEWS: (state, { data, count, lastCount }) => {
    //     data[lastCount].selected = false;
    //     data[count].selected = true;
    //     state.hotNews = [...data];
    // }
};

const actions = {
    async ShoppingAddressList({ commit }, payload) {
        const res = await ShoppingAddressList(payload);
        state.addressList = res.result.shoppingAddressVms
        return
    },
    async GetProductList({ commit }, payload) {
        const res = await GetProductList(payload);
        state.get_Product = res.result
        return
    },
    async CreateOrder({ commit }, payload) {
        const res = await CreateOrder(payload);
        state.get_CreateOrder = res.result
        return
    },
    async GetShoppingAddress({ commit }, payload) {
        const res = await GetShoppingAddress(payload);
        state.get_GetShoppingAddress = res.result
        return
    },
    async DeleteShoppingAddress({ commit }, payload) {
        const res = await DeleteShoppingAddress(payload);
        return
    },
    async CreateShoppingAddress({ commit }, payload) {
        const res = await CreateShoppingAddress(payload);
        state.get_CreateShoppingAddress = res.result
        return
    },
    async UpdateShoppingAddress({ commit }, payload) {
        const res = await UpdateShoppingAddress(payload);
        state.get_UpdateShoppingAddress = res.result
        return
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
