import request from "@/utils/request";
import qs from 'qs'

export const GetAreaList = (data) => {
    return request({
        url: "/Area/GetAreaList",
        method: "get",
        params: data,
        type: "1"
    });
};
export const checkPhone = (data) => {
    return request({
        url: "/TokenAuth/Check",
        method: "get",
        params: data,
        type: "1"
    });
};
export const GetPhoneCode = (data) => {
    return request({
        url: "/TokenAuth/GetPhoneCode",
        method: "get",
        params: data,
        type: "1"
    });
};
export const RegisterEnterprise = (data) => {
    return request({
        url: "/TokenAuth/RegisterEnterprise",
        method: "post",
        data: qs.stringify(data),
        type: "1"
    });
};