import request from "@/utils/request";
import qs from 'qs'

export const queryHomeData = () => {
  return request({
    url: "/WebHome/HomePage",
    method: "get",
    type: "1"
  });
};

export const queryDashboardData = () => {
  return request({
    url: "/WebSite/HomePage",
    method: "get",
    type: "1"
  });
};

export const createSuggestions = (data) => {
  return request({
    url: "/WebHome/CreateSuggestions",
    method: "post",
    data: qs.stringify(data),
    type: "1"
  });
};

export const ResidualSearch = () => {
  return request({
    url: "/RequreCarOrderStatus/ResidualSearch",
    method: "get",
    type: "1"
  });
};

export const getFetchYwhzrx = () => {
  return request({
    url: "/WebSite/GetYwhzrx",
    method: "get",
    type: "1"
  });
};

export const CalculateCarValue = (data) => {
  return request({
    url: "/WebHome/CalculateCarValue",
    method: "post",
    data: qs.stringify(data),
    type: "1"
  });
};