
import { getOrderStatus, getRequreCar } from "@/api/personalCenter";
const state = {
  orderStatus: {},
  requreCarData: {}
};

const mutations = {
  SET_ORDERSTATUS: (state, result) => {
    state.orderStatus = result;
  },
  SET_REQUERECARDATA: (state, result) => {
    state.requreCarData = result;
  }
};

const actions = {
  async getOrderStatus({ commit }, payload) {
    const res = await getOrderStatus(payload);
    if (res.success) {
      commit("SET_ORDERSTATUS", res.result);
    } else {
      throw res;
    }
  },
  async getRequreCar({ commit }, payload) {
    const res = await getRequreCar(payload);
    if (res.success) {
      commit("SET_REQUERECARDATA", res.result);
    } else {
      throw res;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};

