import { getShoppingCartCount } from "@/api/user";

export default {
	async getShoppingCartCount({ commit }) {
			const cartRes = await getShoppingCartCount();
			if (cartRes.success) {
				commit("UPDATE_CARCOUNT", cartRes.result.count);
			} else {
				throw cartRes
			}
	},
};