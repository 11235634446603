import { GetOrderDetail, UpdateOrderPayMethod, OrderPay, UpdateOrderProductOrderStatus, OrderWxPay, OrderCancel, WxPayStatus } from "@/api/pay";
const state = {
    get_GetOrderDetail: {},
    get_UpdateOrderPayMethod: {},
    get_OrderPay: {},
    get_UpdateOrderProductOrderStatus: {},
    get_OrderWxPay: {},
    get_OrderCancel: {},
    get_WxPayStatus: {},
};

const mutations = {
    //   SET_HOMEDATA: (state, homeData) => {
    //     console.log(homeData);
    //     state.homeData = homeData;
    //     state.hotNews = homeData.hotNews;
    //   },
    //   SET_HOTNEWS: (state, { data, count, lastCount }) => {
    //     data[lastCount].selected = false;
    //     data[count].selected = true;
    //     state.hotNews = [...data];
    //   }
};

const actions = {
    async GetOrderDetail({ commit }, payload) {
        const res = await GetOrderDetail(payload);
        state.get_GetOrderDetail = res.result
        return
    },
    async UpdateOrderPayMethod({ commit }, payload) {
        const res = await UpdateOrderPayMethod(payload);
        state.get_UpdateOrderPayMethod = res.result
        return
    },
    async OrderPay({ commit }, payload) {
        const res = await OrderPay(payload);
        state.get_OrderPay = res.result
        return
    },
    async UpdateOrderProductOrderStatus({ commit }, payload) {
        const res = await UpdateOrderProductOrderStatus(payload);
        state.get_UpdateOrderProductOrderStatus = res.result
        return
    },
    async OrderWxPay({ commit }, payload) {
        const res = await OrderWxPay(payload);
        state.get_OrderWxPay = res.result
        return
    },
    async OrderCancel({ commit }, payload) {
        const res = await OrderCancel(payload);
        state.get_OrderCancel = res.result
        return
    },
    async WxPayStatus({ commit }, payload) {
        const res = await WxPayStatus(payload);
        state.get_WxPayStatus = res.result
        return
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
