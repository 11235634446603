import {
  getCommodityListPage,
  getCommodityTypes,
  getCommodityDetail,
  createCommodity,
  getCommodities
} from "@/api/bulkCommodity";
const state = {
  commodityTypesList: [],
  commodityListPage: [],
  commoditiesList: [],
  pagination: {},
  commodityDetailData: {}
};

const mutations = {
  SET_COMMODITY_TYPY: (state, { list }) => {
    state.commodityTypesList = list;
  },
  SET_COMMODITY: (state, { list }) => {
    state.commoditiesList = list;
  },
  SET_COMMODITY_LISTPAGE: (state, { commodityListPageVms, ...other }) => {
    state.commodityListPage = commodityListPageVms;
    state.pagination = other;
  },
  SET_COMMODITYDETAIL: (state, data) => {
    state.commodityDetailData = data;
  }
};

const actions = {
	async getCommodityListPage ({ commit }, payload) {
		commit("UPDATE_LOADING", true, { root: true });
    const res = await getCommodityListPage(payload);
    if (res.success) {
			commit("SET_COMMODITY_LISTPAGE", res.result);
			commit("UPDATE_LOADING", false, { root: true });
    } else {
      throw res;
    }
  },
  async getCommodityTypes({ commit }) {
    const res = await getCommodityTypes();
    if (res.success) {
      commit("SET_COMMODITY_TYPY", res.result);
    } else {
      throw res;
    }
    return res
  },
  async getCommodities({ commit }, payload) {
    const res = await getCommodities(payload);
    if (res.success) {
      commit("SET_COMMODITY", res.result);
    } else {
      throw res;
    }
  },
  async getCommodityDetail({ commit }, payload) {
    const res = await getCommodityDetail(payload);
    if (res.success) {
      commit("SET_COMMODITYDETAIL", res.result);
    } else {
      throw res;
    }
  },
  async createCommodity({ commit }, payload) {
    const res = await createCommodity(payload);
    if (res.success) {
      return res.result;
    } else {
      throw res;
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
