import {
  getShoppingCartList,
  updateShoppingCartCount,
  deleteShoppingCart,
} from "@/api/shoppingCar";
const state = {
  shoppingCartList: [],
};

const mutations = {
  SET_SHIPCARLIST: (state, { getShoppingCartVms }) => {
    state.shoppingCartList = getShoppingCartVms;
  },
  
};

const actions = {
	async getShoppingCartList ({ commit }) {
		commit("UPDATE_LOADING", true, { root: true });
    const res = await getShoppingCartList();
    if (res.success) {
			commit("SET_SHIPCARLIST", res.result);
			commit("UPDATE_LOADING", false, { root: true });
		} else {
			commit("UPDATE_LOADING", false, { root: true });
      throw res;
    }
  },
  async deleteShoppingCart({ commit }, payload) {
    const res = await deleteShoppingCart(payload);
    if (res.success) {
        return res.success
    } else {
      throw res;
    }
  },
  async updateShoppingCartCount({ commit }, payload) {
    const res = await updateShoppingCartCount(payload);
    if (res.success) {
        return res.success
    } else {
      throw res;
    }
  },

};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
