import request from '@/utils/request';
/**
 * 登录
 * @param  userType  0后台用户 1企业用户 2车主用户
 * @param password 密码
 * @param userNameOrEmailAddress 电话号码
 */
export const userLogin = (data) => {
  return request({
    url: 'TokenAuth/Authenticate',
    method: 'post',
    data
  });
};
export const getShoppingCartCount = () => {
  return request({
    url: '/WebProduct/GetShoppingCartCount',
    method: "get",
    type: '1'
  });
};
/**
 * 获取用户信息
 */
// export const getUserInfo = (data) => {
//   return request({
//     url: 'TokenAuth/Authenticate',
//     method: 'post',
//     data
//   });
// };
