import axios from "axios";
import router from "@/router";
import { getToken } from "@/utils/cookies";
import { Message } from "element-ui";
class HttpRequest {
  destroy() { }
  interceptors(instance, url) {
    // 请求拦截
    instance.interceptors.request.use(
      config => {
        // 添加全局的loading...
        if (config.type) {
          const token = getToken();
          config.headers["Authorization"] = "Bearer " + token;
          config.headers.post["Content-Type"] =
            "application/x-www-form-urlencoded";
          return config;
        } else {
          config.headers.post["Content-Type"] =
            "application/x-www-form-urlencoded";
        }
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );
    // 响应拦截
    instance.interceptors.response.use(
      res => {
        if (url) {
          this.destroy(url);
        }
        const { data, status } = res;
        if (status === 200 && data && data.success) {
          return data;
        } // 请求成功
        return requestFail(res); // 失败回调
      },
      error => {
        // if(!getToken() && window.location.pathname !== '/register/login'){
        //   Message({
        //     message: "您还没有登录 请登录",
        //     type: "warning",
        //     duration: 3 * 1000
        //   });
        //   window.location.href = '/register/login'
        //   return false
        // }
        if (url) {
          this.destroy(url);
        }
        let errMsg = "请求异常";
        if (error.response && error.response.status) {
          switch (error.response.status) {
            case 401:
							errMsg = "登录授权已过期，请重新登录";
							console.log(error)
							if (window.location.pathname !== url) {
								window.location.href = '/register/login'
							}
              break;
            case 415:
              errMsg = "没有权限进行此操作";
              break;
          }
        }
        Message({
          message: errMsg,
          type: "error",
          duration: 3 * 1000
        });
        return Promise.reject(error);
      }
    );
  }
  async request(options) {
    const instance = axios.create({
      baseURL: `${process.env.VUE_APP_API_BASE_URL}api/`
    });
    await this.interceptors(instance, options.url);
    return instance(options);
  }
}

// 请求失败
const requestFail = res => {
  const errStr = "网络繁忙！";
  // token失效重新登录
  if (res.data.status === 401) {
    console.error("token失效重新登录");
    return router.replace({ name: "login" });
  }

  return Promise.reject(res.data.errmsg || errStr);
};

// 合并axios参数
const conbineOptions = (url, data, method, other) => {
  const options = {
    method: method || "GET",
    url,
    ...other
  };
  return options.method !== "GET"
    ? Object.assign(options, { data: data })
    : Object.assign(options, { params: data });
};

const HTTP = new HttpRequest();

/**
 * 抛出整个项目的api方法
 */
const request = ({ url, data, method, ...other }) => {
  const newOpts = conbineOptions(url, data, method, other);
  const res = HTTP.request(newOpts);
  return Promise.resolve(res);
};

export default request;
