import request from "@/utils/request";
import qs from 'qs'

export const getCommodityListPage = data => {
  return request({
    url: "/WebBulk/GetCommodityListPage",
    method: "post",
    data: qs.stringify(data),
    type: "1"
  });
};

export const createCommodity = data => {
  return request({
    url: "/WebBulk/CreateCommodity",
    method: "post",
    data: qs.stringify(data),
    type: "1"
  });
};

export const getCommodityTypes = () => {
  return request({
    url: "/WebBulk/GetCommodityTypes",
    method: "get",
    type: "1"
  });
};

export const getCommodities = data => {
  return request({
    url: "/WebBulk/GetCommodities",
    method: "get",
    params: data,
    type: "1"
  });
};

export const getCommodityDetail = data => {
  return request({
    url: "/WebBulk/GetCommodityDetail",
    method: "get",
    params: data,
    type: "1"
  });
};
