import request from "@/utils/request";
import qs from 'qs'

export const getCancleReasonSelectList = data => {
  return request({
    url: "/Order/GetCancleReasonSelectList",
    method: "get",
    type: '1'
  });
};

export const downlodaFile = data => {
  return request({
    url: "/Bulk/DownLoadBidding",
    method: "get",
    type: '1',
    params: data,
    responseType: 'blob'
  });
};