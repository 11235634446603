import {
  getProductList,
  getProductTypeOne,
  getProductDetails,
  addShoppingCart,
  getProductTypeTwo
} from "@/api/sparePartsMall";
const state = {
  productList: [],
  productTypeOneList: [],
  productTypeTwoList: [],
  pagination: {},
  loading: false,
  productDetailsData: {}
};

const mutations = {
  SET_PRODUCLIST: (state, { getProductVms, ...other }) => {
    state.productList = getProductVms;
    state.pagination = other;
  },
  SET_PRODUCTTYPEONE: (state, { list }) => {
    state.productTypeOneList = list;
  },
  SET_PRODUCTTYPETWO: (state, { list }) => {
    state.productTypeTwoList = list;
  },
  SET_PRODUCTDETAIL: (state, data) => {
    state.productDetailsData = data;
  },
  SET_LOADING: (state, data) => {
    state.loading = data;
  },
};

const actions = {
	async getProductList ({ commit }, payload) {
		commit("SET_LOADING", true);
    const res = await getProductList(payload);
    if (res.success) {
			commit("SET_PRODUCLIST", res.result);
			commit("SET_LOADING", false);
		} else {
			commit("SET_LOADING", false);
      throw res;
    }
  },
  async getProductTypeOne({ commit }) {
    const res = await getProductTypeOne();
    if (res.success) {
      commit("SET_PRODUCTTYPEONE", res.result);
    } else {
      throw res;
    }
  },
  async getProductTypeTwo({ commit }, payload) {
    const res = await getProductTypeTwo(payload);
    if (res.success) {
      commit("SET_PRODUCTTYPETWO", res.result);
    } else {
      throw res;
    }
  },
  async getProductDetails({ commit }, payload) {
    const res = await getProductDetails(payload);
    if (res.success) {
      commit("SET_PRODUCTDETAIL", res.result);
      return res.success
    } else {
      throw res;
    }
  },
  async addShoppingCart({ commit }, payload) {
    const res = await addShoppingCart(payload);
    if (res.success) {
      return res.success
    } else {
      throw res;
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};